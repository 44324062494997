import { useState } from 'react';

import { css } from '@emotion/react';
import {
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';

type MonsiteclubPriceProps = {
  taxes: {
    id: string;
    value: number;
  };
};

export const MonsiteclubPrice = ({ taxes }: MonsiteclubPriceProps) => {
  const [optionsPrice, setOptionsPrice] = useState(0);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormGroup
        sx={{ flexDirection: 'row', transform: 'scale(0.8)' }}
        color="primary"
      >
        <Typography gutterBottom component="p" variant="body1">
          Options
        </Typography>
        <FormControlLabel
          onClick={(e) => {
            e.stopPropagation();
          }}
          control={
            <Checkbox
              size="small"
              color="secondary"
              onChange={(e) => {
                if (e.target.checked) {
                  setOptionsPrice(optionsPrice + 360);
                } else {
                  setOptionsPrice(optionsPrice - 360);
                }
              }}
            />
          }
          color="primary"
          label={`Migration de votre ancien site internet (${
            360 * taxes.value
          }€ ${taxes.id})`}
        />
        <FormControlLabel
          onClick={(e) => {
            e.stopPropagation();
          }}
          control={
            <Checkbox
              size="small"
              color="secondary"
              onChange={(e) => {
                if (e.target.checked) {
                  setOptionsPrice(optionsPrice + 240);
                } else {
                  setOptionsPrice(optionsPrice - 240);
                }
              }}
            />
          }
          label={`Boutique en ligne automatisée (${240 * taxes.value}€ ${
            taxes.id
          })`}
        />
        <FormControlLabel
          onClick={(e) => {
            e.stopPropagation();
          }}
          control={
            <Checkbox
              size="small"
              color="secondary"
              onChange={(e) => {
                if (e.target.checked) {
                  setOptionsPrice(optionsPrice + 120);
                } else {
                  setOptionsPrice(optionsPrice - 120);
                }
              }}
            />
          }
          label={`1 heure de formation (${120 * taxes.value}€ ${taxes.id})`}
        />
      </FormGroup>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: bolder;
            font-size: 1.5em;
          `}
        >
          {Math.ceil((600 + optionsPrice) * taxes.value)}€
        </Typography>
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: lighter;
          `}
        >
          {taxes.id}
        </Typography>
      </Box>
      <Typography
        component="p"
        variant="body2"
        css={css`
          text-align: center;
          line-height: 1em;
        `}
      >
        Puis <b>{12 * taxes.value}€</b> {taxes.id}/mois après 3 ans
      </Typography>
    </Box>
  );
};
