import { css } from '@emotion/react';
import { Typography, Box } from '@mui/material';

type ClubhousePriceProps = {
  taxes: {
    id: string;
    value: number;
  };
};

export const ClubhousePrice = ({ taxes }: ClubhousePriceProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 0.5,
      }}
    >
      <Typography
        component="p"
        css={css`
          text-align: center;
          line-height: 1em;
          font-weight: bolder;
          font-size: 1.5em;
        `}
      >
        {300 * taxes.value}€
      </Typography>
      <Typography
        component="p"
        css={css`
          text-align: center;
          line-height: 1em;
          font-weight: lighter;
        `}
      >
        {taxes.id}/an
      </Typography>
    </Box>
  );
};
