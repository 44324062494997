import { useState } from 'react';

import { css } from '@emotion/react';
import {
  Typography,
  Box,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

type ConvocationPriceProps = {
  taxes: {
    id: string;
    value: number;
  };
};

export const ConvocationPrice = ({ taxes }: ConvocationPriceProps) => {
  const [memberLength, setMemberLength] = useState(100);
  const [optionsPrice, setOptionsPrice] = useState(0);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <FormGroup
        sx={{ flexDirection: 'row', transform: 'scale(0.8)' }}
        color="secondary"
      >
        <FormControlLabel
          onClick={(e) => {
            e.stopPropagation();
          }}
          control={
            <Checkbox
              size="small"
              color="secondary"
              onChange={(e) => {
                if (e.target.checked) {
                  setOptionsPrice(optionsPrice + 65);
                } else {
                  setOptionsPrice(optionsPrice - 65);
                }
              }}
            />
          }
          color="secondary"
          label="Option : envoi des convocations par SMS (1000 sms)"
        />
      </FormGroup>
      <Box sx={{ width: '100%' }}>
        <Typography gutterBottom component="p" variant="body2">
          J’indique mon nombre de licenciés
        </Typography>
        <Slider
          value={memberLength}
          step={1}
          min={0}
          max={1200}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(event, newValue) => {
            setMemberLength(newValue as number);
          }}
          valueLabelDisplay="auto"
          marks={[
            { value: 0 },
            { value: 100 },
            { value: 200 },
            { value: 300 },
            { value: 400 },
            { value: 500 },
            { value: 600 },
            { value: 700 },
            { value: 800 },
            { value: 900 },
            { value: 1000 },
            { value: 1100 },
            { value: 1200 },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: bolder;
            font-size: 1.5em;
          `}
        >
          {Math.ceil(
            (200 + Math.floor(memberLength / 100) * 100 + optionsPrice) *
              taxes.value
          )}
          €
        </Typography>
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;

            font-weight: lighter;
          `}
        >
          {taxes.id}/an
        </Typography>
      </Box>
      <Typography
        component="p"
        variant="body2"
        css={css`
          text-align: center;
          line-height: 1em;
        `}
      >
        Pour <b>{memberLength}</b> licenciés
      </Typography>
    </Box>
  );
};
