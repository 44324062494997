import { useState } from 'react';

import { css } from '@emotion/react';
import { Box, Stack, Switch, Typography } from '@mui/material';
import { Types } from '@scorenco/core';

import { Wrapper } from '../../primitives';

import { Product } from './Product';

type PricingSliceProps = {
  slice: Types.ComponentSlicesPricing;
};

export const PricingSlice = ({ slice }: PricingSliceProps) => {
  const availableTaxes = [
    { id: 'HT', value: 1 / 1.2 },
    { id: 'TTC', value: 1 },
  ];
  const [taxes, setTaxes] = useState(availableTaxes[1]);

  return (
    <Wrapper marginY={5}>
      {(slice.products?.[0]?.type !== 'cityscore' ||
        slice.products?.length > 1) && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ marginLeft: 5, marginBottom: 2 }}
        >
          <Typography>HT</Typography>
          <Switch
            checked={taxes.id === 'TTC'}
            size="small"
            onChange={() => {
              setTaxes(
                taxes.id === 'HT' ? availableTaxes[1] : availableTaxes[0]
              );
            }}
          />
          <Typography>TTC</Typography>
        </Stack>
      )}
      <Box
        css={css`
          display: flex;
          flex-flow: wrap;
          justify-content: space-evenly;
          row-gap: 3rem;
          column-gap: 1rem;
          margin: 0 auto;
          align-items: stretch;
        `}
      >
        {slice.products?.map((product) => {
          if (!product) {
            return null;
          }

          return (
            <Product
              key={product.id}
              product={product}
              taxes={taxes}
              css={css`
                flex: 0 0 16rem;
              `}
            />
          );
        })}
      </Box>
    </Wrapper>
  );
};
