import { css } from '@emotion/react';
import { Typography, Box } from '@mui/material';

export const SimplePrice = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 0.5,
      }}
    >
      <Typography
        component="p"
        css={css`
          text-align: center;
          line-height: 1em;
          font-weight: bolder;
          font-size: 1.5em;
        `}
      >
        GRATUIT
      </Typography>
    </Box>
  );
};
