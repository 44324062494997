import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { EmojiEvents, EmojiEventsOutlined } from '@mui/icons-material';
import { Typography, Box, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#FED385',
  },
  '& .MuiRating-iconHover': {
    color: '#FED385',
  },
});

type TournoiPriceProps = {
  taxes: {
    id: string;
    value: number;
  };
};

export const TournoiPrice = ({ taxes }: TournoiPriceProps) => {
  const [tournamentLength, setTournamentLength] = useState(1);

  useEffect(() => {
    if (!tournamentLength) {
      setTournamentLength(1);
    }
  }, [tournamentLength]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Typography
        component="p"
        variant="body2"
        css={(theme) => css`
          margin-top: ${theme.spacing(1)};
          text-align: center;
          line-height: 1em;
          margin-bottom: 0.5em;
        `}
      >
        <b>{(49 * taxes.value).toFixed(2)}€</b> {taxes.id} par tournoi
      </Typography>

      <StyledRating
        value={tournamentLength}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(event, newValue) => {
          setTournamentLength(newValue as number);
        }}
        precision={1}
        icon={<EmojiEvents fontSize="inherit" />}
        emptyIcon={<EmojiEventsOutlined fontSize="inherit" />}
        max={7}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: bolder;
            font-size: 1.5em;
          `}
        >
          {(49 * tournamentLength * taxes.value).toFixed(2)}€
        </Typography>
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: lighter;
          `}
        >
          {taxes.id}
        </Typography>
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: lighter;
          `}
        >
          pour {tournamentLength} tournoi{tournamentLength > 1 && 's'}
        </Typography>
      </Box>
    </Box>
  );
};
