import { useMemo } from 'react';

import { css } from '@emotion/react';
import { Chip, Typography } from '@mui/material';
import { Box, StackProps } from '@mui/system';
import { Types } from '@scorenco/core';

import { Image, Markdown } from '../../primitives';
import { Card } from '../../primitives/Card';

import {
  ClubhousePrice,
  ConvocationPrice,
  MonsiteclubPrice,
  SimplePrice,
  TournoiPrice,
  CityscorePrice,
  GrandChelemPrice,
  WidgetsPremiumPrice,
} from './ProductPrice';

type ProductProps = StackProps & {
  product: Types.ComponentPricingProduct;
  taxes: { id: string; value: number };
};

const PRODUCTS_PRICES_CONFIG = {
  simple: {
    id: 'simple',
    component: SimplePrice,
  },
  monsiteclub: {
    id: 'monsiteclub',
    component: MonsiteclubPrice,
  },
  convocation: {
    id: 'convocation',
    component: ConvocationPrice,
  },
  tournoi: {
    id: 'tournoi',
    component: TournoiPrice,
  },
  clubhouse: {
    id: 'clubhouse',
    component: ClubhousePrice,
  },
  cityscore: {
    id: 'cityscore',
    component: CityscorePrice,
  },
  grandchelem: {
    id: 'grandchelem',
    component: GrandChelemPrice,
  },
  widgets_premium: {
    id: 'widgets_premium',
    component: WidgetsPremiumPrice,
  },
};

export const Product = ({ product, taxes }: ProductProps) => {
  const ProductPriceComponent = useMemo(() => {
    return product.type && PRODUCTS_PRICES_CONFIG[product.type]?.component;
  }, [product.type]);

  return (
    <Card
      sx={{ width: '20rem', justifyContent: 'space-between !important' }}
      disabled={true}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Image
          src={product.icon?.data?.attributes?.url}
          css={css`
            object-fit: contain;
            width: 6rem;
            height: 100%;
          `}
        />
        <Typography
          css={(theme) => css`
            margin-top: ${theme.spacing(1)};
            text-align: center;
            line-height: 1em;
            margin-bottom: ${!product.is_favorite
              ? 'calc(19.5px + 0.5em)'
              : '0.5em'};
            font-weight: bolder;
            font-size: 1.2em;
            text-transform: uppercase;
          `}
        >
          {product.name}
        </Typography>
        {product.is_favorite && (
          <Chip label="Coup de coeur" color="error" size="small" />
        )}
        <Markdown
          value={product.features}
          css={css`
            margin-top: 1rem;
            margin-bottom: 2rem;
            font-size: 0.8em;
            width: 100%;

            ul {
              padding: 0;
            }

            li {
              position: relative;
              list-style-type: none;
              padding-left: 1.75rem;
              margin-bottom: 0.5rem;
            }

            li::before {
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              margin-right: 0.25rem;
              content: url('/images/verified-icon.png');
            }
          `}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {!!ProductPriceComponent && <ProductPriceComponent taxes={taxes} />}
        <Markdown value={product.cta} sx={{ textAlign: 'center' }} />
      </Box>
    </Card>
  );
};
