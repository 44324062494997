import { Box } from '@mui/material';

type CityscorePriceProps = {
  taxes: {
    id: string;
    value: number;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CityscorePrice = ({ taxes }: CityscorePriceProps) => {
  // const [memberLength, setMemberLength] = useState(10000);
  // const [optionsPrice, setOptionsPrice] = useState(0);

  // Simulation du prix désactiver par Lucas pour devis Google Sheet
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {/* <FormGroup
        sx={{ flexDirection: 'row', transform: 'scale(0.8)' }}
        color="primary"
      >
        <Typography gutterBottom component="p" variant="body1">
          Options
        </Typography>
        <FormControlLabel
          onClick={(e) => {
            e.stopPropagation();
          }}
          control={
            <Checkbox
              size="small"
              color="secondary"
              onChange={(e) => {
                if (e.target.checked) {
                  setOptionsPrice(optionsPrice + 399);
                } else {
                  setOptionsPrice(optionsPrice - 399);
                }
              }}
            />
          }
          color="primary"
          label={`Kit communication (${(399 * 1.2 * taxes.value).toFixed(2)}€ ${
            taxes.id
          })`}
        />
        <FormControlLabel
          onClick={(e) => {
            e.stopPropagation();
          }}
          control={
            <Checkbox
              size="small"
              color="secondary"
              onChange={(e) => {
                if (e.target.checked) {
                  setOptionsPrice(optionsPrice + 350);
                } else {
                  setOptionsPrice(optionsPrice - 350);
                }
              }}
            />
          }
          color="primary"
          label={`Réunion d'information (${350 * 1.2 * taxes.value}€ ${
            taxes.id
          })`}
        />
      </FormGroup>
      <Box sx={{ width: '100%' }}>
        <Typography gutterBottom component="p" variant="body2">
          J’indique le nombre d'habitants
        </Typography>
        <Slider
          onClick={(e) => {
            e.stopPropagation();
          }}
          value={memberLength}
          step={1000}
          min={10000}
          max={50000}
          onChange={(event, newValue) => {
            setMemberLength(newValue as number);
          }}
          valueLabelDisplay="auto"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;
            font-weight: bolder;
            font-size: 1.5em;
          `}
        >
          {memberLength *
            0.1 *
            1.2 *
            // * 1.2 car les prix CityScore sont par défauts en HT
            taxes.value}
          €
        </Typography>
        <Typography
          component="p"
          css={css`
            text-align: center;
            line-height: 1em;

            font-weight: lighter;
          `}
        >
          {taxes.id}/an
        </Typography>
      </Box>
      <Typography
        component="p"
        css={css`
          text-align: center;
          line-height: 1em;
          font-size: 1em;
        `}
      >
        +{' '}
        <b>
          {(
            (490 + Math.floor(memberLength / 10000) * 100 + optionsPrice) *
            1.2 *
            // * 1.2 car les prix CityScore sont par défauts en HT
            taxes.value
          ).toFixed(2)}
          €{' '}
        </b>
        {taxes.id} la première année
      </Typography>
      <Typography
        component="p"
        variant="body2"
        css={css`
          text-align: center;
          line-height: 1em;
        `}
      >
        Pour <b>{memberLength}</b> habitants
      </Typography>
      {memberLength >= 40000 && (
        <Typography
          component="p"
          variant="body2"
          css={css`
            text-align: center;
            line-height: 1em;
            font-style: italic;
          `}
        >
          Pour plus de 50000 habitants, contactez-nous pour établir un devis.
        </Typography>
      )} */}
    </Box>
  );
};
